import React from 'react';
import './overlay.css';

const Overlay = ({ isVisible, onClose, redirectUrl, redirectText }) => {
    if (!isVisible) return null;

    return (
        <div className="overlay">
            <div className="overlay-content">
                <p>Olá, seja bem-vindo(a) ao nosso canal de validação de identidade!</p>

                <p>A sua segurança é a nossa prioridade. O objetivo desse portal é prevenir que os seus dados sejam utilizados indevidamente por terceiros e garantir a transparência na nossa relação.</p>

                <p>Levamos muito a sério o nosso compromisso com o cumprimento das leis que protegem sua privacidade e, caso deseje entender melhor sobre como as nossas empresas realizam o tratamento de dados pessoais ou fazer solicitações a respeito dos seus dados, acesse o nosso{' '}
                    <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
                        {redirectText}
                    </a>{' '}
                    para mais informações.
                </p>
               
                <button onClick={onClose} className="button-container">OK</button>
            </div>
        </div>
    );
};

export default Overlay;