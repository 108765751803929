function extrairNomeDaEmpresa() {
  const hostname = window.location.hostname;

  const partes = hostname.split('.');

  const posicaoAnalise = partes.indexOf('analise');

  if (posicaoAnalise !== -1 && posicaoAnalise < partes.length - 1) {
    const nomeEmpresa = partes[posicaoAnalise + 1];
    return nomeEmpresa;
  }

  return null;
}

export default extrairNomeDaEmpresa;
