export function defineTokenSdk(nomeDaEmpresa) {
    let token;
  
    switch (nomeDaEmpresa) {
      case 'toyotakyoto':
        token = process.env.REACT_APP_TOKEN_SDK_KYOTO;
        break;
      case 'osakaveiculos':
        token = process.env.REACT_APP_TOKEN_SDK_OSAKA;
        break;
      case 'kurumaveiculos':
        token = process.env.REACT_APP_TOKEN_SDK_KURUMA;
        break;
      case 'vitoriadiesel':
        token = process.env.REACT_APP_TOKEN_SDK_VITORIADIESEL;
        break;
      case 'jeep':
        token = process.env.REACT_APP_TOKEN_SDK_VITORIAMOTORS;
        break;
      case 'mercedes':
        token = process.env.REACT_APP_TOKEN_SDK_VITORIAMOTORS;
        break;
      case 'byd':
        token = process.env.REACT_APP_TOKEN_SDK_VITORIAMOTORS;
        break;
      case 'savana':
        token = process.env.REACT_APP_TOKEN_SDK_SAVANA;
        break;
      default:
        token = process.env.REACT_APP_TOKEN_SDK;
        break;
    }
  
    return token;
  }