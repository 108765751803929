export function validarCPF(cpf) {
  
    const cpfArray = Array.from(cpf, Number);
  
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += cpfArray[i] * (10 - i);
    }
    let primeiroDigito = 11 - (soma % 11);
    if (primeiroDigito >= 10) {
      primeiroDigito = 0;
    }
  
    if (primeiroDigito !== cpfArray[9]) {
      return false;
    }
  
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += cpfArray[i] * (11 - i);
    }
    let segundoDigito = 11 - (soma % 11);
    if (segundoDigito >= 10) {
      segundoDigito = 0;
    }
  
    if (segundoDigito !== cpfArray[10]) {
      return false;
    }
  
    return true;
  }