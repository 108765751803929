import React from 'react';
import './documentAlreadySentPage.css';
import { redirectToOtherSite } from '../../services/redirectToOtherSite';
import { useParams } from 'react-router-dom';
import extrairParteDaURL from '../../services/RetornaDns';

function DocumentAlreadySentPage() {
  const {identifier} = useParams();
  
  let identifierUrl = identifier;
  if(!identifierUrl)
  {
    identifierUrl = extrairParteDaURL();
  }

  return (
    <div className="container">
      <div className="message-box">
        <img src={process.env.PUBLIC_URL + '/images/Green_check_box-removebg-preview.png'} 
          alt="Check"
          className='bottom-image'
          width="100"
          height="100"
        />
        <h1 style={{ fontSize: '1.8rem'}}>Documento já enviado para esse CPF. Aguarde o retorno.</h1>
        <button className="ok-button" onClick={() => redirectToOtherSite(identifierUrl)}>OK</button>
      </div>
    </div>
  );
}

export default DocumentAlreadySentPage;
