import React from 'react';
import './ImageBotton.css';

function ImageBottom() {
  return (
    <section>
      <div className="image-container">
        <img src={process.env.PUBLIC_URL + '/images/Empresas_div_comercio-01.png'} 
        alt="Banner Logos"
        className='bottom-image'
        />
      </div>
    </section>
  );
}

export default ImageBottom;
