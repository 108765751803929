import axios from 'axios';
import extrairParteDaURL from './RetornaDns';

export const gatewayApi = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    timeout: 10000,
    withCredentials: false,
  });

export function salvarTokenSdk(token, cpf, identifierUrl) {
    if(!identifierUrl)
    {
        identifierUrl = extrairParteDaURL();
    }
    gatewayApi.post('DataIntegrate/DocumentsToken', {
        "sdkToken": token,
        "status": "Pending",
        "cpf": cpf,
        "identifier": identifierUrl
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export async function getCpfById(cpf) {
    try {
        const response = await gatewayApi.get('DataIntegrate/CheckIfCpfExist?cpf=' + cpf);
        if (response.status === 200){
            return response.data;
        }
    } catch (error) {
        console.log(error);
        return error.response.status;
    }
}


