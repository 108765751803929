import React from 'react';
import './successPage.css';
import { redirectToOtherSite } from '../../services/redirectToOtherSite';
import { useParams } from 'react-router-dom';
import extrairNomeDaEmpresa from '../../services/RetornaDns';


function SuccessPage() {
  const {identifier} = useParams();
  
  let identifierUrl = identifier;
  if(!identifierUrl)
  {
    identifierUrl = extrairNomeDaEmpresa();
 }
 debugger
  return (
    <div className="container">
        <div className="message-box">
            <img src={process.env.PUBLIC_URL + '/images/Green_check_box-removebg-preview.png'} 
            alt="Check"
            className='bottom-image'
            width="100"
            height="100"
            />
            <h1 style={{ fontSize: '1.8rem'}}>Documentos enviados com sucesso. Aguarde o retorno.</h1>
            <button className="ok-button" onClick={() => redirectToOtherSite(identifierUrl)}>OK</button>
        </div>
    </div>
  );
}

export default SuccessPage;
