import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './homePage.css';
import { validarCPF } from '../../services/validarCPF';
import InputMask from 'react-input-mask';
import { getCpfById } from '../../services/api';
import Overlay from './components/overlay.js'; // Certifique-se de que o caminho está correto

function HomePage() {
  const navigate = useNavigate();
  const [cpf, setCPF] = useState('');
  const { identifier} = useParams();
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  const handleCPFChange = (event) => {
    const cleanedCPF = event.target.value.replace(/\D/g, '');
    setCPF(cleanedCPF);
  };

  const handleCloseOverlay = () => {
    setIsOverlayVisible(false);
  };

  const handleNavigate = async () => {
    if (validarCPF(cpf)) {
      try {
        const cpfExists = await getCpfById(cpf);
        debugger
        if (cpfExists === false) {
          if(identifier)
            navigate(`/envioDocumentos/${identifier}/${cpf}`);
          else
            navigate(`/envioDocumentos/${cpf}`);
        } else if (cpfExists === 500){
          alert('Informe um CPF válido.')
        } else {
          if(identifier)
            navigate(`/documentAlreadySent/${identifier}`);
          else 
            navigate('/documentAlreadySent');
          
        }
      } catch (error) {
        console.log(error);
        alert('Ocorreu um erro ao verificar o CPF. Por favor, tente novamente mais tarde.');
      }
    } else {
      alert('Informe um CPF válido.');
    }
    
  };


  return (
    <>
      <Overlay
        isVisible={isOverlayVisible}
        onClose={handleCloseOverlay}
        redirectUrl="https://privacidadecomercio.aguiabranca.com.br/"
        redirectText="Portal de Privacidade"
      />
      <div className={`container ${isOverlayVisible ? 'hidden' : ''}`}>
        <div className="container">
          <h1>Informe o CPF</h1>
          <div className="input-container">
            <InputMask
              mask= '999.999.999-99'
              value={cpf}
              onChange={handleCPFChange}
              placeholder="000.000.000-00"
              />
            <div className="button-container">
              <button onClick={handleNavigate}>Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </>
      
  );
}

export default HomePage;
