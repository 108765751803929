import extrairParteDaURL from '../../services/RetornaDns';
import { salvarTokenSdk } from '../../services/api';
import { defineTokenSdk } from '../../services/defineTokenSdk';
import './sdkPage.css'
import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


function SdkPage() { 
  const navigate = useNavigate();
  const { cpf, identifier} = useParams();
  const notRenderSDK = useRef(true);
      useEffect(() => {
        debugger
        let identifierUrl = identifier;
        if(!identifierUrl)
        {
          identifierUrl = extrairParteDaURL();
        }
        if(notRenderSDK.current){
            notRenderSDK.current = false;
        // eslint-disable-next-line no-undef
            idwSDKWeb({
              
                token: defineTokenSdk(identifierUrl),
                inputMode: 'camera',
                onRender: () => {
                  console.log('it renders!');
                },
                onComplete: ({ token }) => {
                  salvarTokenSdk(token, cpf, identifierUrl)
                  if(identifierUrl)
                    navigate(`/successPage/${identifierUrl}`);
                  else
                    navigate(`/successPage`);
                },
                onError: (error) => {
                  alert(error);
                }
              });
        }
    })

  return (
    <div className='containerSdk'>
        <div data-idw-sdk-web></div>
    </div>
  );
};
export default SdkPage;