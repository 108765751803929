
export function redirectToOtherSite(nomeDaEmpresa) {
  let url;

  switch (nomeDaEmpresa) {
    case 'toyotakyoto':
      url = process.env.REACT_APP_URL_KYOTO;
      break;
    case 'osakaveiculos':
      url = process.env.REACT_APP_URL_OSAKA;
      break;
    case 'kurumaveiculos':
      url = process.env.REACT_APP_URL_KURUMA;
      break;
    case 'vitoriadiesel':
      url = process.env.REACT_APP_URL_VITORIADIESEL;
      break;
    case 'jeep':
      url = process.env.REACT_APP_URL_VITORIAMOTORS_JEEP;
      break;
    case 'mercedes':
      url = process.env.REACT_APP_URL_VITORIAMOTORS_MERCEDES;
      break;
    case 'byd':
      url = process.env.REACT_APP_URL_BYD;
      break;
    case 'savana':
      url = process.env.REACT_APP_URL_SAVANA;
      break;
    default:
      url = window.location.hostname;
      break;
  }

  window.location.href = url;
}
