import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/homePage/homePage';
import SdkPage from './pages/sdkPage/sdkPage';
import ImageBottom from './pages/homePage/components/ImageBotton';
import SuccessPage from './pages/successPage/successPage';
import DocumentAlreadySentPage from './pages/documentAlreadySentPage/documentAlreadySentPage';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/successPage" element={<SuccessPage />} />
            <Route path="/documentAlreadySent" element={<DocumentAlreadySentPage />} />
            <Route path="/successPage/:identifier?" element={<SuccessPage />} />
            <Route path="/documentAlreadySent/:identifier?" element={<DocumentAlreadySentPage />} />
            <Route path="/:identifier" element={<HomePage />} />
            <Route path="/envioDocumentos/:identifier?/:cpf" element={<SdkPage />} />
          </Routes>
        </header>
        <ImageBottom />
      </div>
    </Router>
  );
}

export default App;
